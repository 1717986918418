$(function() {

	// Product page - Get language variable & set file links
	if($('.productgrid__single').length) {
		var url = new URL(window.location.href);
		var lang = url.searchParams.get('lang');
		
		// Set default
		if(lang == null) {
			lang = 'gb';
		}

		// Generate links
		$('.productgrid__single-link').each(function() {
			var productCode = $(this).data('productcode');
			var href = '/lib/data/' + lang + '-' + productCode + '.pdf';
			$(this).attr('href', href);
		});
	}
});